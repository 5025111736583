<template>
  <el-popconfirm
    confirm-button-text='Да'
    cancel-button-text='нет'
    icon="el-icon-question"
    title="Вы уверены, что хотите удалить элемент?"
    @confirm="$emit('element-deleted', scope.row)"
  >
    <el-button
      slot="reference"
      class="table-action-button"
      type="default"
      round
      size="mini"
      icon="fas fa-trash"
      :loading="loading"
    >
    </el-button>
  </el-popconfirm>
</template>

<script>

import ClickToEdit from "@/components/ClickToEdit";
export default {
  name: 'matrix-actions-table-column',
  components: {ClickToEdit},

  props: {
    scope: {type: Object, required: true},
  },
  data() {
    return {
      loading: false,
    }
  },
  computed: {},
  methods: {

  }
}

</script>

<style>

</style>
